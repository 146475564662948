import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { providersApi } from './api-slices/providers.api'
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'

import logger from 'redux-logger'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist'
import helperSlice from './slices/helper.slice'
import basketSlice from './slices/basket.slice'
import notificationSlice from './slices/notification.slice'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: 'basket',
  blacklist: 'notification'
}
const persistNotificationConfig = {
  key: 'notification',
  storage: storageSession
}

const rootReducer = combineReducers({
  [providersApi.reducerPath]: providersApi.reducer,
  helper: helperSlice,
  basket: basketSlice,
  notification: persistReducer(persistNotificationConfig, notificationSlice)
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV === 'development'
      ? getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
          }
        })
          .concat(providersApi.middleware)
          .concat(logger)
      : getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
          }
        }).concat(providersApi.middleware)
})

export const persistor = persistStore(store)

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
