import { createSlice } from '@reduxjs/toolkit'

export const helperSlice = createSlice({
  name: 'helper',
  initialState: {
    searchKeyWord: ''
  },
  reducers: {
    setSearchKeyWord: (state, action) => {
      state.searchKeyWord = action.payload
    }
  }
})
export const { setSearchKeyWord } = helperSlice.actions

export default helperSlice.reducer
