import React from 'react'
import ReactDOM from 'react-dom'
import './assets/scss/main.scss'
import App from './App'
import { persistor, store } from './redux/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import './i18n'
import { HelmetProvider } from 'react-helmet-async'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { CookiesProvider } from 'react-cookie'
import ScrollToTop from './components/common/scroll/scroll-top'

ReactDOM.render(
  <>
    {/*<React.StrictMode>*/}
    <CookiesProvider>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <PersistGate persistor={persistor}>
            <GoogleReCaptchaProvider
              reCaptchaKey={window?.configs?.RECAPTCHA_SITE_KEY}
              scriptProps={{
                async: true, // optional, default to false,
                appendTo: 'head' // optional, default to "head", can be "head" or "body",
              }}
            >
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </GoogleReCaptchaProvider>
          </PersistGate>
        </BrowserRouter>
      </Provider>
    </CookiesProvider>
    {/*</React.StrictMode>*/}
  </>,
  document.getElementById('root')
)

// If you want your redux to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more text-pages service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
