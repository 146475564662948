import { createSlice } from '@reduxjs/toolkit'

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    show: false
  },
  reducers: {
    setShowNotification: (state, action) => {
      state.show = action.payload
    }
  }
})
export const { setShowNotification } = notificationSlice.actions

export default notificationSlice.reducer
