import { createSlice } from '@reduxjs/toolkit'

export const basketSlice = createSlice({
  name: 'basket',
  initialState: {
    basketData: []
  },
  reducers: {
    setBasketData: (state, action) => {
      state.basketData.push(action.payload)
    },
    removeItemFromBasket: (state, action) => {
      state.basketData = state.basketData?.filter(
        (item) => item.basketItemId !== action.payload
      )
    },
    clearBasketData: (state, action) => {
      if (!action.payload) state.basketData = []
      else
        state.basketData = state.basketData?.filter(
          (item) => !action.payload?.includes(item.basketItemId)
        )
    },
    clearNotAllowedItems: (state, action) => {
      state.basketData = state.basketData?.filter((item) =>
        action.payload?.includes(item.providerId)
      )
    }
  }
})
export const {
  setBasketData,
  clearBasketData,
  removeItemFromBasket,
  clearNotAllowedItems
} = basketSlice.actions

export default basketSlice.reducer
