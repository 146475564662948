import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import i18n from '../../i18n'
import generateId from '../../helpers/random-id.helper'

export const providersApi = createApi({
  reducerPath: 'providersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: window?.configs?.BASE_URL,
    prepareHeaders: (headers) => {
      headers.set('content-type', 'application/json')
      headers.set('accept', 'application/json')
      headers.set('Accept-Language', i18n.language)
      return headers
    }
  }),
  tagTypes: [
    'PaymentCards',
    'FAQ',
    'TextPages',
    'PaymentInfo',
    'P2pSession',
    'CommissionInfo',
    'Banners',
    'InsuranceProviderFields'
  ],
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => `v1/providers/categories`
    }),
    getProviders: builder.query({
      query: () => `v1/providers`
    }),
    getCards: builder.query({
      query: (providerId) =>
        `v1/payments/cards${providerId ? `?providerId=${providerId}` : ''}`,
      providesTags: ['PaymentCards']
    }),
    getFAQ: builder.query({
      query: () => `v1/FAQs`,
      providesTags: ['FAQ']
    }),
    getTextPages: builder.query({
      query: () => `v1/TextPages`,
      providesTags: ['TextPages']
    }),
    getSocialNetworks: builder.query({
      query: () => `v1/SocialNetworks`
    }),
    getContactInfo: builder.query({
      query: () => `v1/Contact`
    }),
    getCardIcons: builder.query({
      query: () => `v1/PaymentProviders`
    }),
    getOrderInfo: builder.query({
      query: ({ orderCode, token, isDefault }) =>
        `${isDefault ? 'v1/' : 'v1/c2c/'}payments/${orderCode}?token=${token}`,
      providesTags: ['PaymentInfo']
    }),
    getBanners: builder.query({
      query: (page) => `v1/banners?page=${page}`,
      providesTags: ['Banners']
    }),
    getCommissionInfo: builder.query({
      query: (providerId) => `v1/payments/commissions/charges/${providerId}`,
      providesTags: ['CommissionInfo']
    }),
    getSession: builder.query({
      query: () => `v1/c2c/session`,
      providesTags: ['P2pSession']
    }),
    getInsuranceProviders: builder.query({
      query: () => `v2/insurance/providers`
    }),

    getBalance: builder.mutation({
      query: (body) => {
        let params = {
          ...body,
          list: [...body.list]?.sort(
            (a, b) => a.name.slice(-1) - b.name.slice(-1)
          )
        }

        return {
          url: `v1/providers/${body?.direct ? 'direct-' : ''}balance${
            body.verify ? '/confirm' : ''
          }`,
          method: 'POST',
          body: params
        }
      },
      transformResponse: (baseQueryReturnValue, meta, arg) => {
        return baseQueryReturnValue?.balanceInformations?.map((obj) => ({
          ...obj,
          balanceCheckArgs: { ...arg, providerId: obj?.providerId },
          uiId: generateId()
        }))
      }
    }),
    getCommissions: builder.mutation({
      query: (body) => {
        return {
          url: `v1/payments/commissions`,
          method: 'POST',
          body
        }
      }
    }),
    getInsuranceProviderFields: builder.query({
      query: (providerId) => `v2/insurance/providers/${providerId}/fields`,
      providesTags: ['InsuranceProviderFields']
    }),
    getInsuranceProviderDefaultPrice: builder.mutation({
      query: (body) => {
        return {
          url: `v2/insurance/providers/${body?.providerId}/default-price`,
          method: 'POST',
          body
        }
      }
    }),
    calculateInsuranceProviderPrice: builder.mutation({
      query: (body) => {
        return {
          url: `v2/insurance/policy/calculate-price`,
          method: 'POST',
          body: {
            ...body,
            policy: {
              ...body.policy,
              additionalRiskInsuranceId:
                body.policy?.additionalRiskInsuranceId?.id
            }
          }
        }
      },
      transformResponse: (baseQueryReturnValue, meta, arg) => {
        return {
          ...baseQueryReturnValue,
          providerId: arg?.providerId,
          dateFrom: arg.dateFrom,
          dateTo: arg.dateTo,
          limitId: arg.limitId,
          client: arg.client,
          policy: {
            ...baseQueryReturnValue?.policies?.[0],
            additionalRiskInsuranceId: arg.policy?.additionalRiskInsuranceId,
            holder: arg.policy?.holder
          },
          additionalParameters: baseQueryReturnValue?.additionalParameters
        }
      },
      keepUnusedDataFor: 5 * 60
    }),

    confirmInsurance: builder.mutation({
      query: (body) => {
        return {
          url: `v2/insurance/policy`,
          method: 'POST',
          body
        }
      }
    }),
    insertPayment: builder.mutation({
      query: (body) => {
        return {
          url: `v1/payments`,
          method: 'POST',
          body
        }
      }
    }),
    sendOtp: builder.mutation({
      query: (body) => {
        return {
          url: `v1/providers/balance/send`,
          method: 'POST',
          body
        }
      }
    }),
    resendOtp: builder.mutation({
      query: (body) => {
        return {
          url: `v1/providers/balance/resend`,
          method: 'POST',
          body
        }
      }
    }),
    sendMail: builder.mutation({
      query: ({ orderCode, isDefault, ...body }) => {
        return {
          url: `${
            isDefault ? 'v1/' : 'v1/c2c/'
          }payments/cheque/${orderCode}/send-email`,
          method: 'POST',
          body
        }
      }
    })
  })
})

export const {
  useGetCategoriesQuery,
  useGetProvidersQuery,
  useGetCardsQuery,
  useGetFAQQuery,
  useGetTextPagesQuery,
  useGetCardIconsQuery,
  useGetSocialNetworksQuery,
  useGetSessionQuery,
  useGetCommissionInfoQuery,
  useGetContactInfoQuery,
  useGetBalanceMutation,
  useGetOrderInfoQuery,
  useGetCommissionsMutation,
  useSendMailMutation,
  useGetBannersQuery,
  useInsertPaymentMutation,
  useGetInsuranceProviderFieldsQuery,
  useGetInsuranceProvidersQuery,
  useCalculateInsuranceProviderPriceMutation,
  useGetInsuranceProviderDefaultPriceMutation,
  useConfirmInsuranceMutation,
  useSendOtpMutation,
  useResendOtpMutation
} = providersApi
