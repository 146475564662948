import React, { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PayLoader } from './components/common/loader/pay-loader'
import { LoadingProvider } from './components/hooks/loadingContext.hook'

const Main = lazy(() => import('./pages/main/main'))
const Search = lazy(() => import('./pages/search/search'))
const Providers = lazy(() => import('./pages/providers/providers'))
const Balance = lazy(() => import('./pages/balance/balance'))
const Basket = lazy(() => import('./pages/basket/basket'))
const TextPage = lazy(() => import('./pages/text-pages/text-page'))
const Help = lazy(() => import('./pages/help/help'))
const Success = lazy(() => import('./pages/success/success'))
const Failed = lazy(() => import('./pages/failed/failed'))
const NotFound = lazy(() => import('./pages/not-found/not-found'))
const Layout = lazy(() => import('./pages/layout/layout'))
const PaymentInfo = lazy(() => import('./pages/payment-info/payment-info'))
const DirectBalance = lazy(() => import('./pages/balance/direct-balance'))

function App() {
  return (
    <Suspense fallback={<PayLoader inCenter={true} isLoading={true} />}>
      <LoadingProvider>
        <Routes>
          <Route
            exact={true}
            path={'direct/:providerCode'}
            element={<DirectBalance />}
          />
          <Route element={<Layout />}>
            <Route index element={<Main />} />
            <Route
              path={'*'}
              element={<Navigate to={'notFound'} replace={true} />}
            />
            <Route path={'notFound'} element={<NotFound />} exact={true} />
            <Route
              exact={true}
              path={'categories/:categoryId/providers'}
              element={<Providers />}
            />
            <Route
              path={'providers/:providerId/balance/*'}
              element={<Balance />}
            />
            <Route exact={true} path={'basket'} element={<Basket />} />
            <Route exact={true} path={'FAQ'} element={<Help />} />
            <Route
              exact={true}
              path={'success/:orderCode/:type'}
              element={<Success />}
            />
            <Route exact={true} path={'failed'} element={<Failed />} />
            <Route
              exact={true}
              path={'payment/info'}
              element={<PaymentInfo />}
            />
            <Route exact={true} path={'providers'} element={<Search />} />
            <Route
              exact={true}
              path={'info/:pageTitle'}
              element={<TextPage />}
            />
          </Route>
        </Routes>
      </LoadingProvider>
    </Suspense>
  )
}

export default App
